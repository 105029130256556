// .NET CLIENT (WPF) COLORS
$wpf-client-bg: #1a1a1a;
$wpf-client-bg-transparent: #f51a1a1a;
$wpf-client-card-bg: #333;
$wpf-client-card-bg-alt: #4d4d4d;
$wpf-client-card-text-soft: #aaa;
$wpf-client-card-hover-bg: #666;
$wpf-client-dialog-border: #000;
$wpf-client-dialog-bg: #424242;
$wpf-client-message-box-bg: #272727;
$wpf-client-basic: #67666a;
$wpf-client-strong: #cbc9cf;
$wpf-client-accent: #fed103;
$wpf-client-accent-md: #eebe0c;
$wpf-client-accent-strong: #e7a909;
$wpf-client-disabled-bg: #212121;
$wpf-client-disabled-border: #636363;
$wpf-client-disabled-foreground: #636363;
$wpf-client-black-transparent: #b000;
$wpf-client-white-transparent: #bfff;
$wpf-client-white: #f6f6f6;
$wpf-client-black: black;
$wpf-client-green: limegreen;
$wpf-client-red: #f20;
$wpf-alert-bg: #a5a5a5;
$wpf-list-view-bg: #313131;

// BRAND COLORS:
$color-intrepid: #ffd100; // 49 100% 50% 1 HSLA
$color-asphalt: #2d2926; // 42 8% 16% 1 HSLA

$color-intrepid-secondary: HSL(49 80% 65%);

// GRIDSMART UI COLORS
// Colors defined with a range of 100 (lightest) to 900 (darkest)
// Lighter colors should be used for backgrounds and to demphasize
// Darker colors should be used for text, borders and for emphasis

// $primary is official GRIDSMART intrepid yellow
$gs-primary: $color-intrepid;
$gs-primary-100: hsl(50, 95%, 93%);
$gs-primary-200: "";
$gs-primary-300: hsl(49, 90%, 75%);
$gs-primary-400: "";
$gs-primary-500: $color-intrepid;
$gs-primary-600: "";
$gs-primary-700: hsl(46, 73%, 37%);
$gs-primary-800: "";
$gs-primary-900: hsl(43, 80%, 23%);

// Gray is based off our $secondary color, GRIDSMART's asphalt color
$gs-secondary: $color-asphalt;
$gs-gray: $color-asphalt;
$gs-gray-100: hsl(45, 25%, 98%);
$gs-gray-200: hsl(43, 6%, 75%);
$gs-gray-300: hsl(43, 3%, 69%);
$gs-gray-400: hsl(42, 4%, 56%);
$gs-gray-500: hsl(41, 2%, 45%);
$gs-gray-600: hsl(40, 2%, 38%);
$gs-gray-700: hsl(41, 2%, 30%);
$gs-gray-800: hsl(41, 3%, 25%);
$gs-gray-900: hsl(42, 8%, 16%);

// hsl(0, 0%, 46%)
// #f2f2f2

/*******************
  GRIDSMART COLORS
 *******************/
.color-intrepid {
  color: $color-intrepid;
}

.color-asphalt {
  color: $color-asphalt;
}

// GRAYS/NEUTRALS
.gray-100 {
  color: $gs-gray-100;
}
.gray-200 {
  color: $gs-gray-200;
}
.gray-300 {
  color: $gs-gray-300;
}
.gray-400 {
  color: $gs-gray-400;
}
.gray-500 {
  color: $gs-gray-500;
}
.gray-600 {
  color: $gs-gray-600;
}
.gray-700 {
  color: $gs-gray-700;
}
.gray-800 {
  color: $gs-gray-800;
}
.gray-900 {
  color: $gs-gray-900;
}

// BRAND COLORS
.primary-100 {
  color: $gs-primary-100;
}
.primary-200 {
  color: $gs-primary-200;
}
.primary-300 {
  color: $gs-primary-300;
}
.primary-400 {
  color: $gs-primary-400;
}
.primary-500 {
  color: $gs-primary-500;
}
.primary-600 {
  color: $gs-primary-600;
}
.primary-700 {
  color: $gs-primary-700;
}
.primary-800 {
  color: $gs-primary-800;
}
.primary-900 {
  color: $gs-primary-900;
}

// BACKGROUNDS
.bg-primary-100 {
  background-color: $gs-primary-100;
}
.bg-primary-200 {
  background-color: $gs-primary-200;
}
.bg-primary-300 {
  background-color: $gs-primary-300;
}
.bg-primary-400 {
  background-color: $gs-primary-400;
}
.bg-primary-500 {
  background-color: $gs-primary-500;
}
.bg-primary-600 {
  background-color: $gs-primary-600;
}
.bg-primary-700 {
  background-color: $gs-primary-700;
}
.bg-primary-800 {
  background-color: $gs-primary-800;
}
.bg-primary-900 {
  background-color: $gs-primary-900;
}

.bg-gray-100 {
  background-color: $gs-gray-100;
}
.bg-gray-200 {
  background-color: $gs-gray-200;
}
.bg-gray-300 {
  background-color: $gs-gray-300;
}
.bg-gray-400 {
  background-color: $gs-gray-400;
}
.bg-gray-500 {
  background-color: $gs-gray-500;
}
.bg-gray-600 {
  background-color: $gs-gray-600;
}
.bg-gray-700 {
  background-color: $gs-gray-700;
}
.bg-gray-800 {
  background-color: $gs-gray-800;
}
.bg-gray-900 {
  background-color: $gs-gray-900;
}
